import ReactGA from 'react-ga4';

export default function useGoogleAnalytics() {
	if (!window.location.host.includes('localhost:')) {
		ReactGA.initialize(
			process.env.REACT_APP_MODERN_DESGIN === 'true' ? 'G-C2HW9GNX5J' : 'G-R6ER2LCMHT',
		);
		// ReactGA.pageview(window.location.pathname + window.location.search);
		// Send pageview with a custom path
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
		});
	}
	return null;
}
